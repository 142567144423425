









































import { defineComponent } from '@vue/composition-api';
import { asyncComputed } from '@vueuse/core';

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
    section: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const imgSrc = asyncComputed(async () => {
      if (!props.section.img) return;

      const result = await import(`../data/images/${props.section.img}`);
      return result.default;
    });

    const imgSrc2 = asyncComputed(async () => {
      if (!props.section.img2) return;

      const result = await import(`../data/images/${props.section.img2}`);
      return result.default;
    });

    return {
      imgSrc,
      imgSrc2,
    };
  },
});
