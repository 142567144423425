









import { defineComponent } from 'vue-demi';
import HelpSection from '../components/HelpSection.vue';
import data from '../data/help.json';

export default defineComponent({
  components: { HelpSection },
  setup() {
    return {
      data,
    };
  },
});
